<template>
    <div class="card">
        <div class="card-header bg-transparent">
            <div class="row align-items-center">
            <div class="col">
                <h6 class="text-uppercase text-muted ls-1 mb-1">{{cardSubTitle ?? 'Sub Title'}}</h6>
                <h5 class="h3 mb-0">{{cardTitle ?? 'Title'}}</h5>
            </div>
            </div>
        </div>
        <div class="card-body">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoButtonWhiteCardComponent',
    props: [
        'cardTitle',
        'cardSubTitle'
    ],
}
</script>